import { gql } from 'urql'

const QueryEditJobMutation = `
  mutation (
    $id: Int!,
    $title: String!,
    $status: JobStatus,
    $remoteStatus: JobRemoteStatus,
    $departmentId: Int,
    $ownerId: Int!,
    $description: String!,
    $pitch: String,
    $salaryFrom: Float,
    $salaryTo: Float,
    $currency: JobCurrency,
    $employmentType: JobEmployment,
    $typeOfSalary: JobTypeOfSalary,
    $recruiterIds: [Int!],
    $hiringManagerIds: [Int!],
    $locationIds: [Int!]!,
    $jobCategoryId: Int
    $education: JobEducation,
    $jobLevel: JobJobLevel,
    $skills: [String!],
    $jobStages: [JSON!],
    $headcount: Int!,
    $customFields: [JSON!],
    $languages: [JSON!],
    $jobTalentPoolIds: [Int!],
    $referenceId: String,
    $taggedIds: [Int!]
  ) {
    jobsUpdate(
      input: {
        id: $id,
        title: $title,
        status: $status,
        remoteStatus: $remoteStatus,
        departmentId: $departmentId,
        ownerId: $ownerId,
        description: $description,
        pitch: $pitch,
        salaryFrom: $salaryFrom,
        salaryTo: $salaryTo,
        currency: $currency,
        employmentType: $employmentType,
        typeOfSalary: $typeOfSalary,
        recruiterIds: $recruiterIds,
        hiringManagerIds: $hiringManagerIds,
        locationIds: $locationIds,
        jobCategoryId: $jobCategoryId,
        education: $education,
        jobLevel: $jobLevel,
        skills: $skills,
        jobStages: $jobStages,
        headcount: $headcount,
        customFields: $customFields,
        languages: $languages,
        jobTalentPoolIds: $jobTalentPoolIds,
        referenceId: $referenceId,
        taggedIds: $taggedIds
      }
    ) {
      job {
        id
      }
    }
  }
`
export const ChangeJobStatusMutation = gql<
  { id: number },
  { id: number; status: string }
>`
  mutation ($id: Int!, $status: JobStatus) {
    jobsUpdate(input: { id: $id, status: $status }) {
      job {
        id
      }
    }
  }
`

export default QueryEditJobMutation
