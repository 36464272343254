import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import pathConfiguration from 'src/configuration/path'
import useSwitchRole from 'src/hooks/authorization/use-switch-role'
import useStaticData from 'src/hooks/data/use-static-data'
import ChangeJobStageWithModalView from '~/components/ChangeJobStageWithModal/ChangeJobStageWithModalView'
import ShareJobModal from '~/components/Jobs/ShareJobModal'
import configuration from '~/configuration'
import { PUBLIC_APP_URL } from '~/core/constants/env'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import { openAlert } from '~/core/ui/AlertDialog'
import { AvatarGroup } from '~/core/ui/AvatarGroup'
import { Badge } from '~/core/ui/Badge'
import { Button } from '~/core/ui/Button'
import ComboboxSelect from '~/core/ui/ComboboxSelect'
import { IDotColorProps } from '~/core/ui/Dot'
import { DropdownMenu, DropdownMenuMenuItem } from '~/core/ui/DropdownMenu'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import { ISelectOption } from '~/core/ui/Select'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn, uuidV4 } from '~/core/ui/utils'
import { formatAddressLocation } from '~/core/utilities/common'
import { defaultFormatDate } from '~/core/utilities/format-date'
import { adminCanAction } from '~/core/utilities/permission'
import CandidatesTabContent from '~/features/jobs/[id]/tabs/candidates/CandidatesTabContent'
import useCareerSettingStore from '~/lib/features/careers/[id]/store'
import { combineDomainCareerPage } from '~/lib/features/careers/[id]/utilities'
import QueryTenantJobDetailMini from '~/lib/features/jobs/graphql/query-job-detail-mini'
import useShareJobLogic from '~/lib/features/jobs/hooks/use-share-job-logic'
import { IJobManagementItemQuery } from '~/lib/features/jobs/types'

import {
  authorizingJobActionMenu,
  authorizingJobStageChange
} from '~/lib/features/jobs/utilities/common'
import {
  JOB_ACTION,
  JOB_APPLICANT_STATUS,
  JOB_DOT_STATUS,
  JOB_STATUS_ENUM
} from '~/lib/features/jobs/utilities/enum'
import usePermissionJob from '~/lib/features/permissions/hooks/use-permission-job'
import useAsyncAction from '~/lib/hooks/use-async-action'
import useBoundStore from '~/lib/store'

interface AsyncActionType<F> {
  loading: boolean
  executed: F
}

export type IJobCard = IJobManagementItemQuery

const Show = (props: { if: boolean; children: ReactNode }) =>
  props.if ? <>{props.children}</> : <></>

const JobCard = ({
  isCompanyKind,
  job,
  deleteAction,
  updateAction,
  callback,
  enablingReferral
}: {
  isCompanyKind: boolean
  enablingReferral: boolean
  job: IJobCard
  deleteAction: AsyncActionType<(id: number) => Promise<any>>
  updateAction: AsyncActionType<(id: number, status: string) => Promise<any>>
  callback?: () => void
}) => {
  const router = useRouter()
  const { careerPageSetting } = useCareerSettingStore()
  const [changeStatus, changingStatus] = useAsyncAction(
    updateAction.executed,
    []
  )
  const [openShareJobModal, setOpenShareJobModal] = useState<boolean>(false)

  const { setToast, user, currentRole } = useBoundStore()
  const { t, i18n } = useTranslation()
  const [openJobPipeLine, setOpenJobPipeline] = useState(false)
  const switchRole = useSwitchRole()
  const { actionJob } = usePermissionJob()
  const { trigger: triggerFetchDetail, data } = useQueryGraphQL({
    query: QueryTenantJobDetailMini,
    variables: {
      id: Number(job.id),
      status: JOB_APPLICANT_STATUS.inprocessing
    },
    shouldPause: true
  })

  const stageTypes = useStaticData({
    keyName: 'agency_stageTypes',
    locale: i18n.language
  })
  const { valueShareJobWithCondition } = useShareJobLogic()
  const checkConditionShareJob = useMemo(() => {
    return valueShareJobWithCondition({
      enablingReferral: enablingReferral || false,
      enablingCareerSite:
        user.currentTenant?.careerSiteSettings?.enablingCareerSiteSetting ||
        false,
      enableJobReferral: job.jobReferable || false,
      jobStatus: job?.status || ''
    })
  }, [enablingReferral, job, user, valueShareJobWithCondition])

  const onConfirmChangeStatus = (value: ISelectOption) => {
    let defaultMsg = t('common:modal:change_job_status_description', {
      title: job.title,
      name: value.supportingObj?.name
    })
    let description = `${defaultMsg} ${
      JOB_STATUS_ENUM.archived === value.value
        ? `${t('common:modal:change_job_status_description_1')}`
        : `${t('common:modal:change_job_status_description_2')}`
    }`
    return openAlert({
      isPreventAutoFocusDialog: false,
      className: 'w-[480px]',
      title: `${t('common:modal:change_job_status_title')}`,
      description,
      actions: [
        {
          label: `${t('button:cancel')}`,
          type: 'secondary',
          size: 'sm'
        },
        {
          label: `${t('button:changeStatusAnyway')}`,
          type: 'destructive',
          size: 'sm',
          onClick: () =>
            changeStatus(parseInt(job.id), value?.value as string).then(() => {
              setToast({
                open: true,
                type: 'success',
                title: t('notification:jobs:jobCard:changesSaved') || ''
              })
            })
        }
      ]
    })
  }

  const listActions = [
    checkConditionShareJob?.jobList?.viewJob && {
      actionID: JOB_ACTION.VIEW,
      label: t('label:optionJobAction:viewJob') || '',
      icon: 'ExternalLink',
      onClick: () => {
        if (!!careerPageSetting) {
          router.push(
            `${combineDomainCareerPage(
              careerPageSetting
            )}${configuration.path.jobs.careerJobView(
              job.tenant.slug,
              job.slug
            )}`
          )
        }
      }
    },
    {
      actionID: JOB_ACTION.EDIT,
      label: t('label:optionJobAction:editJob') || '',
      icon: 'Edit3',
      onClick: () => {
        router.push(configuration.path.jobs.edit(parseInt(job.id)))
      }
    },
    checkConditionShareJob?.jobList?.moreShare && {
      actionID: JOB_ACTION.SHARE_JOB,
      label: t('label:optionJobAction:shareJob') || '',
      icon: 'Share2',
      onClick: () => {
        setOpenShareJobModal(true)
      }
    },
    {
      actionID: JOB_ACTION.DUPLICATE_JOB,
      label: t('label:optionJobAction:duplicate') || '',
      icon: 'Files',
      onClick: () => {
        router.push(configuration.path.jobs.duplicateJob(job.id))
      }
    },
    adminCanAction(currentRole?.code) ||
    user.ownTenant ||
    job.status === 'draft'
      ? {
          actionID: JOB_ACTION.DELETE,
          label: t('label:optionJobAction:deleteJob:title') || '',
          icon: 'Trash2',
          color: 'red',
          onClick: () => {
            openAlert({
              className: 'w-[480px]',
              title: t('label:optionJobAction:deleteJob:title') || '',
              description: job.title ? (
                <Trans
                  i18nKey="label:optionJobAction:deleteJob:descriptionDeleted"
                  values={{
                    jobTitle: job.title
                  }}>
                  <span className="font-medium text-gray-900" />
                </Trans>
              ) : (
                ''
              ),
              actions: [
                {
                  label: t('button:cancel') || '',
                  type: 'secondary',
                  size: 'sm'
                },
                {
                  label: t('button:delete') || '',
                  type: 'destructive',
                  size: 'sm',
                  onClick: () => {
                    deleteAction.executed(parseInt(job.id)).then(() => {
                      setToast({
                        open: true,
                        type: 'success',
                        title: t('notification:jobs:jobCard:deleteJob') || ''
                      })
                    })
                  }
                }
              ]
            })
          }
        }
      : undefined
  ]

  return (
    <div className="w-full">
      <Link
        href={
          job.status === 'draft'
            ? configuration.path.jobs.edit(parseInt(job.id))
            : configuration.path.jobs.detail(parseInt(job.id))
        }
        className={cn(
          `block border-[1px] border-gray-100 bg-white`,
          openJobPipeLine ? 'rounded-t-lg shadow-md' : 'rounded-lg'
        )}>
        <div className="flex items-center pb-3 pl-4 pr-4 pt-3.5">
          <div className="mr-4 flex-1">
            <div className="flex items-center">
              <Tooltip content={job?.title}>
                <div className="line-clamp-1 text-base font-medium text-gray-900 hover:underline">
                  {job.title}
                </div>
              </Tooltip>
              {(job?.statistic?.newApplicantCount || 0) > 0 && (
                <div className="ml-2">
                  <Badge color="green" size="md" radius="circular">
                    {job?.statistic?.newApplicantCount} {t('label:new')}
                  </Badge>
                </div>
              )}
            </div>

            <div className="mt-1 flex min-h-[20px] items-center space-x-1.5">
              {isCompanyKind === true ? (
                <>
                  <Show if={!!job.company?.name}>
                    <IconWrapper
                      size={12}
                      name="Building"
                      className="flex-none text-gray-500"
                    />

                    <Link
                      href={configuration.path.agency.companyDetail(
                        Number(job?.company?.id)
                      )}>
                      <Tooltip content={job.company?.name}>
                        <TypographyText className="line-clamp-1 max-w-[200px] text-sm font-medium text-gray-800 hover:underline">
                          {job.company?.name}
                        </TypographyText>
                      </Tooltip>
                    </Link>
                  </Show>
                  <Show if={job.jobLocations && job.jobLocations.length > 0}>
                    {job.jobLocations?.length ? (
                      <>
                        {job.jobLocations?.length > 1 ? (
                          <Tooltip
                            align="start"
                            content={
                              <>
                                {job.jobLocations.map((item, index: number) => (
                                  <div key={index}>
                                    {formatAddressLocation({
                                      location: {
                                        state: item.state,
                                        country: item.country
                                      }
                                    })}
                                  </div>
                                ))}
                              </>
                            }>
                            <TypographyText className="line-clamp-1 text-sm text-gray-500">
                              {t('job:jobCard:countLocation', {
                                countLocation: job.jobLocations?.length
                              })}
                            </TypographyText>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            content={formatAddressLocation({
                              location: {
                                state: job.jobLocations?.[0]?.state,
                                country: job.jobLocations?.[0]?.country
                              }
                            })}>
                            <TypographyText className="line-clamp-1 text-sm text-gray-500">
                              {formatAddressLocation({
                                location: {
                                  state: job.jobLocations?.[0]?.state,
                                  country: job.jobLocations?.[0]?.country
                                }
                              })}
                            </TypographyText>
                          </Tooltip>
                        )}
                      </>
                    ) : null}
                  </Show>
                  <Show if={!!job.headcount}>
                    <div className="ml-2 mr-2 h-[2px] w-[2px] flex-none rounded-full bg-gray-400" />
                    <IconWrapper
                      size={12}
                      name="Users"
                      className="flex-none text-gray-500"
                    />
                    <Tooltip content={t('tooltip:headcount')}>
                      <TypographyText className="line-clamp-1 text-sm text-gray-600">
                        {job.headcount}
                      </TypographyText>
                    </Tooltip>
                  </Show>
                  <Show if={(job?.tags?.length || 0) > 0}>
                    <div className="ml-2 mr-2 h-[2px] w-[2px] flex-none rounded-full bg-gray-400" />
                    <IconWrapper
                      size={12}
                      name="Tag"
                      className="flex-none text-gray-500"
                    />
                    <Tooltip
                      content={job?.tags?.map((item) => item.name).join(', ')}>
                      <TypographyText className="text-sm text-gray-600">
                        {job?.tags?.map((item) => item.name).join(', ')}
                      </TypographyText>
                    </Tooltip>
                  </Show>
                </>
              ) : (
                <>
                  <Show if={job.jobLocations && job.jobLocations.length > 0}>
                    <div className="flex max-w-[180px] flex-none items-center space-x-2 break-all">
                      <IconWrapper
                        size={12}
                        name="MapPin"
                        className="flex-none text-gray-500"
                      />
                      {job.jobLocations?.length ? (
                        <>
                          {job.jobLocations?.length > 1 ? (
                            <Tooltip
                              align="start"
                              content={
                                <>
                                  {job.jobLocations.map(
                                    (item, index: number) => (
                                      <div key={index}>{`${
                                        item.name ? item.name : ''
                                      }`}</div>
                                    )
                                  )}
                                </>
                              }>
                              <TypographyText className="line-clamp-1 text-sm text-gray-600">
                                {t('job:jobCard:countLocation', {
                                  countLocation: job.jobLocations?.length
                                })}
                              </TypographyText>
                            </Tooltip>
                          ) : (
                            <Tooltip content={job.jobLocations[0].name}>
                              <TypographyText className="line-clamp-1 text-sm text-gray-600">
                                {job.jobLocations[0].name}
                              </TypographyText>
                            </Tooltip>
                          )}
                        </>
                      ) : null}
                    </div>
                  </Show>
                  <Show if={!!job.department?.name}>
                    <div className="ml-2 mr-2 h-[2px] w-[2px] flex-none rounded-full bg-gray-400" />
                    <div className="flex max-w-[180px] items-center break-all">
                      <IconWrapper
                        size={12}
                        name="Network"
                        className="mr-2 flex-none text-gray-500"
                      />
                      <Tooltip content={job.department?.name}>
                        <TypographyText className="line-clamp-1 text-sm text-gray-600">
                          {job.department?.name}
                        </TypographyText>
                      </Tooltip>
                    </div>
                  </Show>
                  <Show if={!!job.headcount}>
                    <div className="ml-2 mr-2 h-[2px] w-[2px] flex-none rounded-full bg-gray-400" />
                    <IconWrapper
                      size={12}
                      name="Users"
                      className="flex-none text-gray-500"
                    />
                    <TypographyText className="line-clamp-1 text-sm text-gray-600">
                      {job.headcount}
                    </TypographyText>
                  </Show>

                  <Show if={(job?.tags?.length || 0) > 0}>
                    <div className="ml-2 mr-2 h-[2px] w-[2px] flex-none rounded-full bg-gray-400" />
                    <IconWrapper
                      size={12}
                      name="Tag"
                      className="flex-none text-gray-500"
                    />
                    <Tooltip
                      content={job?.tags?.map((item) => item.name).join(', ')}>
                      <TypographyText className="text-sm text-gray-600">
                        {job?.tags
                          ?.map((item) => item.name)
                          .slice(0, 2)
                          .join(', ')}
                        {(job?.tags?.length || 0) > 2 &&
                          `, + ${(job?.tags?.length || 0) - 2}`}
                      </TypographyText>
                    </Tooltip>
                  </Show>
                </>
              )}
            </div>
          </div>

          <div>
            <div
              onClick={(e) => e.preventDefault()}
              className="flex items-center justify-end">
              <ComboboxSelect
                closeOnSelect
                dropdownMenuClassName="!w-[356px] right-0"
                hideDropdownIcon={!actionJob.update}
                menuOptionAlign="end"
                menuOptionSide="bottom"
                value={
                  {
                    value: job.status,
                    dot: JOB_DOT_STATUS(job.status || 'gray') as IDotColorProps,
                    supportingObj: {
                      name: {
                        internal: t('label:job_status:internal'),
                        publish: t('label:job_status:publish'),
                        archived: t('label:job_status:archived'),
                        draft: t('label:job_status:draft')
                      }[job.status]
                    }
                  } as ISelectOption
                }
                size="sm"
                isClearable={false}
                isSearchable={false}
                isLoading={changingStatus}
                isDisabled={!actionJob.update}
                onChange={(option) => {
                  const singleValue = option as ISelectOption
                  if (job?.status === JOB_STATUS_ENUM.publish) {
                    onConfirmChangeStatus(singleValue)
                  } else {
                    changeStatus(
                      parseInt(job.id),
                      singleValue?.value as string
                    ).then(() => {
                      setToast({
                        open: true,
                        type: 'success',
                        title: t('notification:jobs:jobCard:changesSaved') || ''
                      })
                    })
                  }
                }}
                options={authorizingJobStageChange(job.status, [
                  {
                    value: JOB_STATUS_ENUM.internal,
                    supportingObj: {
                      name: t('label:job_status:internal'),
                      description: t('label:optionJobStatus:internal') || ''
                    }
                  },
                  {
                    value: JOB_STATUS_ENUM.publish,
                    supportingObj: {
                      name: t('label:job_status:publish'),
                      description: t('label:optionJobStatus:publish') || ''
                    }
                  },
                  {
                    value: JOB_STATUS_ENUM.archived,
                    supportingObj: {
                      name: t('label:job_status:archived'),
                      description: t('label:optionJobStatus:archived') || ''
                    }
                  }
                ])}
              />

              <div className="ml-2">
                <Tooltip
                  content={
                    openJobPipeLine
                      ? t('tooltip:collapse_view')
                      : t('tooltip:expand_view')
                  }>
                  <Button
                    type="secondary"
                    size="xs"
                    iconMenus={openJobPipeLine ? 'Minimize2' : 'Maximize2'}
                    onClick={(e) => {
                      e.preventDefault()
                      setOpenJobPipeline(!openJobPipeLine)
                      if (!openJobPipeLine) {
                        triggerFetchDetail()
                      }
                    }}
                  />
                </Tooltip>
              </div>

              {[JOB_STATUS_ENUM.publish, JOB_STATUS_ENUM.internal].includes(
                job.status
              ) ? (
                <div className="ml-2">
                  <Tooltip
                    content={
                      checkConditionShareJob?.jobList?.shareIcon
                        ? t('label:optionJobAction:shareJob')
                        : t('label:optionJobAction:cannotShareJob')
                    }>
                    <Button
                      isDisabled={!checkConditionShareJob?.jobList?.shareIcon}
                      type="secondary"
                      size="xs"
                      iconMenus="Share2"
                      onClick={(e) => setOpenShareJobModal(true)}
                    />
                  </Tooltip>
                </div>
              ) : null}

              <div className="ml-2">
                {switchRole({
                  default: (
                    <DropdownMenu
                      side="bottom"
                      align="end"
                      menuClassName="w-auto"
                      menu={authorizingJobActionMenu(
                        job.status,
                        listActions.filter(
                          (item) => item
                        ) as (DropdownMenuMenuItem & {
                          actionID: JOB_ACTION
                        })[]
                      )}
                      trigger={(openDropdown) => (
                        <Button
                          type="secondary"
                          showFocusRing={openDropdown}
                          size="xs"
                          iconMenus="MoreHorizontal"
                        />
                      )}></DropdownMenu>
                  ),
                  limited_member: (
                    <Button
                      onClick={() => {
                        if (!!careerPageSetting) {
                          router.push(
                            `${combineDomainCareerPage(
                              careerPageSetting
                            )}${configuration.path.jobs.careerJobView(
                              job.tenant.slug,
                              job.slug
                            )}`
                          )
                        }
                      }}
                      icon="trailing"
                      iconMenus="ExternalLink"
                      label={t('button:viewJob') || ''}
                      type="secondary"
                      size="xs"
                    />
                  )
                })}
              </div>
            </div>

            <div className="mt-2 flex items-center justify-end">
              <div className="flex items-center">
                {job?.createdAt ? (
                  <Tooltip content={t('job:jobCard:createdDate')}>
                    <TypographyText className="text-xs text-gray-500">
                      {defaultFormatDate(new Date(job.createdAt))}
                    </TypographyText>
                  </Tooltip>
                ) : null}
                <Show if={!!job.statistic?.viewCount}>
                  <div className="ml-2 mr-2 h-[2px] w-[2px] rounded-full bg-gray-400" />
                  <TypographyText className="text-xs text-gray-500">
                    {t('job:jobCard:countViews', {
                      count: job.statistic?.viewCount
                    })}
                  </TypographyText>
                </Show>
              </div>
              <If condition={(job?.jobRecruiters || []).length > 0}>
                <div className="ml-2 mr-2 h-[2px] w-[2px] rounded-full bg-gray-400" />
              </If>
              <div>
                <AvatarGroup
                  size="xs"
                  tooltipAlignAvatarCount="end"
                  source={(job?.jobRecruiters || []).map((item) => ({
                    id: parseInt(item.id),
                    alt: item.user?.fullName || item.user?.email,
                    tooltip: item.user?.fullName || item.user?.email,
                    src: item.user?.avatarVariants?.thumb?.url,
                    defaultColour: item.user?.defaultColour
                  }))}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-100"></div>
        <div
          className="flex cursor-pointer justify-between pb-4 pt-3"
          onClick={(e) => {
            e.preventDefault()
            setOpenJobPipeline(!openJobPipeLine)
            if (!openJobPipeLine) {
              triggerFetchDetail()
            }
          }}>
          {(job?.jobStages || []).map((item, index) => {
            const countJobStage =
              Array.isArray(job?.statistic?.applicantsCount) &&
              job?.statistic?.applicantsCount?.find(
                (fi) => fi.job_stage_id === Number(item.id)
              )?.count
            const countColumn = job?.jobStages?.length

            return (
              <div
                key={index}
                style={{
                  width: `calc(100% * (1 / ${countColumn}))`
                }}
                className="flex flex-col items-center justify-center border-r border-gray-100 px-1.5 last:border-r-0">
                <Tooltip content={t('job:jobCard:view_detail')}>
                  <TypographyText className="text-lg font-semibold text-gray-900">
                    <div>{countJobStage || '-'}</div>
                  </TypographyText>
                </Tooltip>
                <Tooltip position="bottom" content={item.stageLabel}>
                  <TypographyText className="line-clamp-1 w-full break-all text-center text-xs text-[#374151]">
                    {item.stageLabel}
                  </TypographyText>
                </Tooltip>
              </div>
            )
          })}
        </div>
      </Link>
      <div className="rounded-b-md bg-gray-100">
        {data?.jobsShow?.jobStages?.length && openJobPipeLine && (
          <ChangeJobStageWithModalView jobTitle={data?.jobsShow?.title || ''}>
            {({
              setOpenMarkAsHired,
              setApplicantCurrent,
              openHiringSuccessModel
            }) => {
              const mergedOptions = {
                setOpenMarkAsHired,
                setApplicantCurrent,
                openHiringSuccessModel
              }

              // eslint-disable-next-line react-hooks/rules-of-hooks
              const getUUidV4 = useMemo(() => uuidV4(), [])
              return (
                <CandidatesTabContent
                  getUUidV4={getUUidV4}
                  jobStatus={data?.jobsShow.status}
                  jobStages={data?.jobsShow?.jobStages}
                  stageTypes={stageTypes}
                  isDragDisabled={job?.status === JOB_STATUS_ENUM.archived}
                  jobId={job.id}
                  jobTitle={data?.jobsShow?.title}
                  placeViewPipeline="jobList"
                  callback={callback}
                  companyName={data?.jobsShow?.company?.name}
                  {...mergedOptions}
                />
              )
            }}
          </ChangeJobStageWithModalView>
        )}
      </div>
      <ShareJobModal
        shareInternal={checkConditionShareJob?.shareInternal}
        sharePublic={checkConditionShareJob?.sharePublic}
        urlReferral={`${PUBLIC_APP_URL}${pathConfiguration.referral.jobDetail({
          jobId: job?.id.toString() || ''
        })}`}
        open={openShareJobModal}
        setOpen={setOpenShareJobModal}
        url={
          careerPageSetting
            ? `${combineDomainCareerPage(
                careerPageSetting
              )}${pathConfiguration?.careers.jobDetail({
                tenantSlug: job?.tenant?.slug,
                jobId: job?.slug
              })}?utm_medium=internal_social_share`
            : ''
        }
      />
    </div>
  )
}

export default JobCard
