import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'urql'
import { ISelectOption } from '~/core/ui/Select'
import { DeleteJobMutation } from '~/lib/features/jobs/graphql/delete-job-mutation'
import QueryCompanyJobList from '~/lib/features/jobs/graphql/query-company-job-list'
import QueryJobList from '~/lib/features/jobs/graphql/query-job-list'
import { ChangeJobStatusMutation } from '~/lib/features/jobs/graphql/submit-edit-job-mutation'
import { useInfinityGraphPage } from '~/lib/features/jobs/hooks/use-infinity-graph-page'
import {
  DEFAULT_SELECTED_JOB_STATUS_ROLE_MAP,
  JOB_STATUS_ENUM
} from '~/lib/features/jobs/utilities/enum'
import { trimObjectProps } from '~/lib/features/tasks/utilities/common'
import useBoundStore from '~/lib/store'

export interface IJobsManagementFilter {
  search?: string
  locationIds?: ISelectOption[]
  hiringMemberIds?: ISelectOption[]
  departmentIds?: Array<ISelectOption>
  status?: ISelectOption[]
  isFilterTouched?: boolean
  companyId?: ISelectOption
  location?: ISelectOption
  operator?: string
  tagIds?: ISelectOption[]
}

const useJobManagement = ({
  isCompanyKind,
  isLoaded
}: {
  isCompanyKind: boolean
  isLoaded: boolean
}) => {
  const { user, currentRole } = useBoundStore()

  const [filterValue, onChangeFilter] = useState<
    IJobsManagementFilter | undefined
  >({
    status: DEFAULT_SELECTED_JOB_STATUS_ROLE_MAP[
      currentRole?.code || 'default'
    ].map((status) => ({
      value: status,
      supportingObj: { name: '' }
    })),
    operator: 'or'
  })

  const jobPaging = useInfinityGraphPage({
    queryDocumentNote: isCompanyKind ? QueryCompanyJobList : QueryJobList,
    getVariable: useCallback(
      (page) => {
        const {
          search,
          status,
          locationIds,
          departmentIds,
          hiringMemberIds,
          companyId,
          location,
          tagIds,
          operator
        } = filterValue || {}
        return trimObjectProps({
          limit: 10,
          page,
          search,
          status:
            (status?.length || 0) > 0
              ? status?.map((status) => status.value)
              : undefined,
          countryStateIds:
            (locationIds?.length || 0) > 0
              ? locationIds?.map((item) => parseInt(item.value))
              : undefined,
          hiringMemberIds:
            (hiringMemberIds?.length || 0) > 0 &&
            hiringMemberIds?.map((item) => parseInt(item.value)),
          departmentIds:
            (departmentIds?.length || 0) > 0
              ? departmentIds?.map((item) => parseInt(item.value))
              : undefined,
          companyId: companyId?.value ? parseInt(companyId?.value) : undefined,
          ...(location?.value
            ? {
                country: location.value.split('*')[1],
                state: location.value.split('*')[0],
                countryStateId: Number(location.id)
              }
            : undefined),
          statusNot: [JOB_STATUS_ENUM.archived],
          tagIds: tagIds?.map((item) => parseInt(item.value)),
          operator: operator
        })
      },
      [filterValue]
    ),
    getPageAttribute: (_lastGroup, groups) => ({
      totalCount: _lastGroup?.jobsList?.metadata?.totalCount,
      pageLength: groups[0]?.jobsList?.collection?.length
    }),
    queryKey: ['job-management-list'],
    enabled: false
  })

  useEffect(() => {
    if (isLoaded) {
      jobPaging.refetch()
    }
  }, [filterValue, isLoaded])

  const [{ fetching: deletingJob }, deleteJob] = useMutation(DeleteJobMutation)
  const [{ fetching: changingJobStatus }, changeJobStatus] = useMutation(
    ChangeJobStatusMutation
  )
  return {
    jobPaging,
    filterControl: useMemo(
      () => ({
        value: filterValue,
        onChange: onChangeFilter
      }),
      [filterValue]
    ),
    action: {
      jobDeleteAction: {
        deleteJob: (args: { id: number }) =>
          deleteJob(args).then(() => jobPaging.refetch()),
        deletingJob
      },
      changeJobStatusAction: {
        changeJobStatus: (args: { id: number; status: string }) =>
          changeJobStatus(args).then(() => jobPaging.refetch()),
        changingJobStatus
      }
    }
  }
}

export default useJobManagement
