import { gql } from 'urql'
import { IJobManagementItemQuery } from '../types'

const QueryJobList = gql<
  {
    jobsList: {
      collection: IJobManagementItemQuery[]
      metadata: { totalCount: number; currentPage: number }
    }
  },
  {
    search?: string
    departmentId?: number | string
    locationIds?: number[]
    status?: string[]
  }
>`
  query (
    $page: Int
    $limit: Int
    $search: String
    $departmentIds: [Int!]
    $locationIds: [Int!]
    $countryStateIds: [Int!]
    $status: [JobStatus!]
    $hiringMemberIds: [Int!]
    $tagIds: [Int!]
    $operator: String
  ) {
    jobsList(
      limit: $limit
      page: $page
      search: $search
      departmentIds: $departmentIds
      countryStateIds: $countryStateIds
      locationIds: $locationIds
      status: $status
      hiringMemberIds: $hiringMemberIds
      tagIds: $tagIds
      operator: $operator
    ) {
      collection {
        id
        title
        status
        jobReferable
        jobLocations {
          name
          address
          state
          city
          country
        }
        slug
        jobStages {
          id
          stageLabel
          stageTypeId
        }
        department {
          name
        }
        industries {
          name
        }
        statistic {
          viewCount
          applicantsCount
          newApplicantCount
        }
        teamMembers {
          id
          avatarVariants
          email
          fullName
          defaultColour
          roles {
            name
          }
        }
        jobRecruiters {
          id
          responsibility
          user {
            email
            fullName
            defaultColour
            avatarVariants
          }
        }
        hiringManagers {
          id
          fullName
          avatarVariants
          email
          defaultColour
          roles {
            name
          }
        }
        recruiters {
          id
          fullName
          avatarVariants
          email
          defaultColour
          roles {
            name
          }
        }
        owner {
          id
          fullName
          avatarVariants
          email
          defaultColour
          roles {
            name
          }
        }
        status
        statusDescription
        createdAt
        tenant {
          slug
        }
        applicants {
          id
          jobId
          coverLetter
          job {
            status
            id
            title
            slug
            owner {
              id
              fullName
              email
            }
          }
          incoming
          createdBy {
            fullName
          }
        }
        headcount
        tags {
          id
          name
        }
      }
      metadata {
        currentPage
        totalCount
      }
    }
  }
`

export default QueryJobList
